<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <customer-cars />
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Lastik Ebatı"
            label-for="id_com_tire_size"
          >
            <v-select
              id="id_com_tire_size"
              v-model="tireHotel.id_com_tire_size"
              placeholder="Lastik Ebatı"
              label="title"
              :options="tireSizes"
              :reduce="tireSizes => tireSizes.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Lastik Markası"
            label-for="id_com_tire_brand"
          >
            <v-select
              id="id_com_tire_brand"
              v-model="tireHotel.id_com_tire_brand"
              placeholder="Lastik Markası"
              label="title"
              :options="tireBrands"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Lastik Tipi"
            label-for="id_com_tire_type"
          >
            <v-select
              id="id_com_tire_type"
              v-model="tireHotel.id_com_tire_type"
              placeholder="Lastik Tipi"
              label="title"
              :options="tireTypes"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Raf"
            label-for="id_com_tire_shelf"
          >
            <v-select
              id="id_com_tire_shelf"
              v-model="tireHotel.id_com_tire_shelf"
              placeholder="Raf"
              label="title"
              :options="tireShelfs"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Teknisyen"
            label-for="id_com_technician"
          >
            <v-select
              id="id_com_technician"
              v-model="tireHotel.id_com_technician"
              placeholder="Teknisyen"
              label="name"
              :options="users"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Lokasyon"
            label-for="id_com_location"
          >
            <v-select
              id="id_com_location"
              v-model="tireHotel.id_com_location"
              placeholder="Lokasyon"
              label="title"
              :options="locations"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="tireHotel.id"
          cols="12"
        >
          <b-form-group
            label="Durum"
            label-for="id_com_tire_status"
          >
            <v-select
              id="id_com_tire_status"
              v-model="tireHotel.id_com_tire_status"
              placeholder="Durum"
              label="title"
              :options="tireStatuses"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Notlar"
            label-for="notes"
          >
            <b-form-textarea
              id="notes"
              v-model="tireHotel.notes"
              placeholder="Notlar"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      @click="saveData"
    >
      Kaydet
    </b-button>
  </validation-observer>
</template>
<script>
import {
  BButton, BCard, BFormGroup, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import CustomerCars from '@/views/Spareparts/TireHotel/elements/CustomerCars.vue'

export default {
  name: 'ItemForm',
  components: {
    ValidationObserver,
    CustomerCars,
    BFormGroup,
    vSelect,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    tireSizes() {
      return this.$store.getters['tireSizes/getTireSizes']
    },
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrands']
    },
    tireTypes() {
      return this.$store.getters['tireTypes/getTireTypes']
    },
    tireShelfs() {
      return this.$store.getters['tireShelfs/getTireShelfs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    tireHotel() {
      return this.$store.getters['tireHotel/dataItem']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    getDataItem() {
      return this.$store.getters['cars/getCar']
    },
    saveStatus() {
      return this.$store.getters['tireHotel/dataSaveStatus']
    },
    tireStatuses() {
      return this.$store.getters['tireStatuses/getTireStatuses']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push('/tirehotel/')
        this.$store.commit('tireHotel/RESET_DATA')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    localize('tr')
    this.getTireSizes()
    this.getTireBrands()
    this.getTireTypes()
    this.getTireShelfs()
    this.getUsers()
    this.getTireStatuses()
    this.getLocations()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getTireSizes() {
      this.$store.dispatch('tireSizes/tireSizesList')
    },
    getTireBrands() {
      this.$store.dispatch('tireBrands/tireBrandsList')
    },
    getTireTypes() {
      this.$store.dispatch('tireTypes/tireTypesList')
    },
    getTireShelfs() {
      this.$store.dispatch('tireShelfs/tireShelfsList')
    },
    getUsers() {
      this.$store.dispatch('users/usersList')
    },
    getTireStatuses() {
      this.$store.dispatch('tireStatuses/tireStatusesList')
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.tireHotel.id_com_customer) {
            this.tireHotel.id_com_customer = this.$route.params.id
          }
          if (!this.tireHotel.id) {
            this.tireHotel.id_com_tire_status = 1
          }
          this.$store.dispatch('tireHotel/saveData', this.tireHotel)
        }
      })
    },
  },
}
</script>
