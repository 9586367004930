<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Lastik Oteli</span>
        </template>
        <item-form />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import AddCar from '@/views/Service/components/AddCar.vue'
import ItemForm from '@/views/Spareparts/TireHotel/ItemForm.vue'

export default {
  name: 'Add',
  components: {
    AddCar,
    BTabs,
    BTab,
    Customer,
    ItemForm,
  },
  created() {
    this.$store.commit('cars/RESET_CARS_LIST')
    this.$store.commit('tireHotel/RESET_DATA')
    this.getCustomer()
    this.getCars()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
    getCars() {
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': this.$route.params.id,
        },
      })
    },
  },
}
</script>
